export default {
  data: () => ({
    loading: true,
    createModal: false,
    editModal: false,
    duplicateModal: false,
    serviceData: {}
  }),
  async mounted () {
    await this.getServiceSNActions()
  },
  methods: {
    async getServiceSNActions () {
      const optionId = this.$router.currentRoute.params.option_id
      const url = `/admin/service_sn_actions/find_per_option_id/${optionId}`
      await this.$http
        .get(url)
        .then(res => {
          this.items = res.data
        })
        .finally(() => {
          this.loading = false
          this.totalRows = this.items.length
        })
    },
    async updateStatus (id) {
      const service = this.items.find(item => item.id === id)
      service.deactivated = service.deactivated === 0 ? 1 : 0
      const params = {
        deactivated: service.deactivated
      }

      await this.$http.patch(`/admin/service_sn_actions/${service.id}`, params)
    },
    open (id) {
      this.serviceData = JSON.parse(
        JSON.stringify(this.items.find(item => item.id === id))
      )
      this.serviceData.has_refill = !!this.serviceData.has_refill
      this.editModal = true
    },
    created (payload) {
      this.items.unshift(payload)
    },
    updated (payload) {
      Object.assign(
        this.items.find(item => item.id === payload.id),
        payload
      )
    },
    deleteModal (item) {
      this.$swal({
        title: `Você tem certeza que deseja deletar a Opção "${item.name}"?`,
        text: 'Essa ação não poderá ser desfeita!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, deletar!',
        cancelButtonText: 'Fechar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.delete(item.id)
        }
      })
    },
    async delete (id) {
      this.loading = true
      const url = `/admin/service_sn_actions/${id}`
      await this.$http
        .delete(url)
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Deletado!',
            text: 'A Opção selecionada foi deletada com sucesso!',
            customClass: {
              confirmButton: 'btn btn-success'
            }
          })

          this.items = this.items.filter(item => item.id !== id)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
