<template>
  <section>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      width="800px"
      @change="(val) => $emit('update:visible', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">
            Editar Serviço
          </h5>

          <div>
            <b-button
              variant="relief-primary"
              size="sm"
              @click.stop="$emit('duplicate', serviceData)"
            >
              Duplicar
            </b-button>

            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <b-form
          class="p-2"
          @submit.prevent="handleSubmit()"
          @reset.prevent="resetForm"
        >
          <b-form-group
            label="Nome"
            label-for="service-name"
          >
            <b-form-input
              id="service-name"
              v-model="serviceData.name"
              autofocus
              trim
              placeholder="Nome do Serviço"
            />
          </b-form-group>

          <b-form-group
            label="Valor"
            label-for="service-value"
          >
            <b-form-input
              id="service-value"
              v-model="serviceData.price_per_thousand"
              autofocus
              trim
              placeholder="Valor do Serviço"
            />
          </b-form-group>

          <b-form-group
            label="API"
            label-for="service-api-name"
          >
            <b-form-select
              id="service-api-name"
              v-model="serviceData.api_name"
              :state="serviceData.api_name === null ? false : true"
              :options="options"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                >Por favor, selecione uma API!</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>

          <b-form-group
            label="Código de Serviço da API"
            label-for="service-api-service-id"
          >
            <b-form-input
              id="service-api-service-id"
              v-model="serviceData.api_service_id"
              autofocus
              trim
              placeholder="Código de Serviço da API"
            />
          </b-form-group>

          <b-form-group
            label="Código de Serviço Local"
            label-for="service-local-service-id"
          >
            <b-form-input
              id="service-local-service-id"
              v-model="serviceData.local_service_id"
              autofocus
              trim
              placeholder="Código de Serviço Local"
            />
          </b-form-group>

          <b-form-group
            label="Preço na API"
            label-for="service-api-price"
          >
            <b-form-input
              id="service-api-price"
              v-model="serviceData.api_price"
              autofocus
              trim
              placeholder="Preço na API"
            />
          </b-form-group>

          <b-form-group
            label="Mínimo de Ações"
            label-for="service-min"
          >
            <b-form-input
              id="service-min"
              v-model="serviceData.min"
              type="number"
              autofocus
              trim
              placeholder="Mínimo de Ações"
            />
          </b-form-group>

          <b-form-group
            label="Máxima de Ações"
            label-for="service-max"
          >
            <b-form-input
              id="service-max"
              v-model="serviceData.max"
              type="number"
              autofocus
              trim
              placeholder="Máxima de Ações"
            />
          </b-form-group>

          <b-form-group
            label="Tem Refill?"
            label-for="service-max"
          >
            <b-form-checkbox
              v-model="serviceData.has_refill"
              class="custom-control-success"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>

          <b-form-group
            label="Descrição"
            label-for="service-description"
          >
            <quill-editor v-model="serviceData.description" />
          </b-form-group>

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Limpar
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </section>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BFormSelectOption,
  BFormCheckbox
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BFormCheckbox,
    quillEditor
  },
  directives: {
    Ripple
  },
  model: {
    prop: 'visible',
    event: 'update:visible'
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    serviceData: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    options: [
      {
        value: 'wiqru',
        text: 'WIQRU'
      },
      {
        value: 'upseguidores',
        text: 'Up Seguidores'
      },
      {
        value: 'drd3m',
        text: 'Drd3m'
      }
    ]
  }),
  methods: {
    clearForm () {
      //
    },
    async handleSubmit () {
      const url = `/admin/service_sn_actions/${this.serviceData.id}`
      const params = {
        ...this.serviceData
      }

      this.$http.patch(url, params).finally(() => {
        this.$emit('updated', this.serviceData)
        this.$emit('update:visible', false)

        this.$swal({
          title: 'Atualizado com sucesso!!',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1'
          },
          buttonsStyling: false
        })
      })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>
